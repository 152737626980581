
import Vue from "vue";
import ButtonRound from "@/components/atoms/ButtonRound.vue";
import LinkIcon from "icons/LinkVariant.vue";
import HamburgerMenu, { Item } from "@/components/molecules/HamburgerMenu.vue";
import { stringify } from "csv/lib/sync";
import { format } from "date-fns";
import { Plot, SubPlot, UpdatePlotPayload, UpdateSubPlotPayload, DisplayFormat } from "@/lib/models/plot";
import { Dialog } from "@/lib/utils";
import PlotDeleteDialog, { PlotDeleteDialogProps } from "@/components/ui/PlotDeleteDialog.vue";
import isMobile from "ismobilejs";

export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    ButtonRound,
    HamburgerMenu,
    LinkIcon,
  },
  props: {
    novelId: String,
    plotId: String,
    plot: Object,
    isViewedByExternalUser: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    plotName() {
      const { plotId } = this;

      if (plotId) return (this.plot as SubPlot).name;
      return "全体プロット";
    },
    getMode() {
      let mode = "起承転結";
      // eslint-disable-next-line default-case
      switch (this.plot.mode) {
        case "johakyu":
          mode = "序破急";
          break;
        case "custom":
          mode = "カスタム";
          break;
        default:
      }
      return mode;
    },
    hamburgerMenuItems() {
      const { plotId, changeMode, downloadCsv, switchDisplayFormat, deletePlot, isPC, showPublishSharedLink } = this;

      const hamburgerMenuItems: Item[] = [
        {
          name: "モードの変更",
          icon: "cog",
          callback() {
            changeMode();
          },
        },
        {
          name: "CSVダウンロード",
          icon: "tray-arrow-down",
          callback() {
            downloadCsv();
          },
        },
      ];

      if (isPC) {
        const switchDisplayFormatMenu: Item = {
          name: "縦横表示の切替",
          icon: "swap",
          callback() {
            switchDisplayFormat();
          },
        };
        hamburgerMenuItems.push(switchDisplayFormatMenu);
      }

      if (isPC) {
        const sharedPlotMenu: Item = {
          name: "作品を共有",
          icon: "shared-novel",
          callback() {
            showPublishSharedLink();
          },
        };
        hamburgerMenuItems.push(sharedPlotMenu);
      }

      if (plotId) {
        const deletePlotMenu: Item = {
          name: "プロットを削除",
          icon: "delete",
          color: "#e87474",
          fontStyle: {
            color: "#e87474",
            fontWeight: "bold",
          },
          callback() {
            deletePlot();
          },
        };
        hamburgerMenuItems.push(deletePlotMenu);
      }

      return hamburgerMenuItems;
    },
    displayFormat() {
      return this.plot.displayFormat || DisplayFormat.HORIZONTAL;
    },
    isPC() {
      return !isMobile().any;
    },
  },
  methods: {
    changeMode() {
      const { $router, novelId, plotId, plot } = this;

      if (plotId) {
        $router.push({
          name: "plotChange",
          params: { novelId, plotId },
          query: { mode: plot.mode || "kishoutenketsu" },
        });
      } else {
        $router.push({
          name: "plotChange",
          params: { novelId },
          query: { mode: plot.mode || "kishoutenketsu" },
        });
      }
    },
    childPlotName(plotId) {
      const subPlots = this.$store.getters["plotModule/subPlots"];
      const subPlot = subPlots.filter((i: SubPlot) => i.plotId === plotId);
      if (subPlot.length > 0) return subPlot[0].name;
      return "";
    },
    clickChildPlot(plotId) {
      const { novelId } = this;
      if (!this.isMulti) {
        this.$router.push({ name: "plot", params: { novelId, plotId } });
      } else {
        this.$emit("clickChildPlot", plotId);
      }
    },
    async downloadCsv() {
      const { plot, plotName } = this;
      const plotGroupName = plot.plotGroups.map((plotGroup) => plotGroup.name);
      const plotGroupDescription = plot.plotGroups.map((plotGroup) => plotGroup.description);
      const input = [plotGroupName, plotGroupDescription];

      let plotContentMaxNum = 0;
      plot.plotGroups.forEach((plotGroup) => {
        if (plotContentMaxNum < plotGroup.plots.length) {
          plotContentMaxNum = plotGroup.plots.length;
        }
      });
      for (let i = 0; i < plotContentMaxNum; i += 1) {
        input.push(plot.plotGroups.map((plotGroup) => (plotGroup.plots[i] ? plotGroup.plots[i].text : "")));
      }

      const csv = await (stringify as any)(input);

      const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
      const blob = new Blob([bom, `\uFEFF${csv}`], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `${format(new Date(), "YYYYMMDD")}_Nolaプロット_${plotName}.csv`;
      link.click();
    },
    switchDisplayFormat() {
      const { $store, plotId, novelId, plot } = this;
      const { dispatch } = $store;

      const changedDisplayFormat =
        plot.displayFormat === DisplayFormat.HORIZONTAL ? DisplayFormat.VERTICAL : DisplayFormat.HORIZONTAL;

      if (plotId) {
        const updateSubPlotPayload: UpdateSubPlotPayload = {
          novelId,
          plotId,
          name: (plot as SubPlot).name,
          plotGroups: plot.plotGroups,
          mode: plot.mode,
          layout: plot.layout,
          displayFormat: changedDisplayFormat,
        };
        dispatch("plotModule/updateSubPlot", updateSubPlotPayload);
      } else {
        const updatePlotPayload: UpdatePlotPayload = {
          novelId,
          plotGroups: plot.plotGroups,
          layout: plot.layout,
          displayFormat: changedDisplayFormat,
        };
        dispatch("plotModule/updatePlot", updatePlotPayload);
      }
    },
    deletePlot() {
      const { novelId, plotId } = this;

      const plotDeleteDialog = new Dialog(PlotDeleteDialog);
      const data: PlotDeleteDialogProps = { novelId, plotId: plotId! };
      plotDeleteDialog.show(data);
    },
    showPublishSharedLink() {
      this.$store.dispatch("sharedLinkModule/showPublishSharedLink");
    },
  },
});

interface Props {
  novelId: string;
  plotId?: string;
  plot: Plot | SubPlot;
  isViewedByExternalUser: boolean;
  isMulti: boolean;
}

interface Data {}

interface Computed {
  plotName: string;
  getMode: string;
  hamburgerMenuItems: Item[];
  displayFormat: DisplayFormat;
  isPC: boolean;
}

interface Methods {
  changeMode(): void;
  downloadCsv(): void;
  switchDisplayFormat(): void;
  deletePlot(): void;
  showPublishSharedLink(): void;
  childPlotName(plotId: string): string;
  clickChildPlot(plotId: string): void;
}
